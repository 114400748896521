@charset "utf-8";

$family-sans-serif: "Helvetica Neue", Arial, Helvetica, sans-serif;
$title-line-height: 1;

// @import "~bulma/bulma";
@import "../node_modules/bulma/sass/utilities/_all.sass";
@import "../node_modules/bulma/sass/base/_all.sass";
@import "../node_modules/bulma/sass/elements/container.sass";
@import "../node_modules/bulma/sass/elements/title.sass";
@import "../node_modules/bulma/sass/elements/button.sass";
@import "../node_modules/bulma/sass/components/navbar.sass";
@import "../node_modules/bulma/sass/layout/footer.sass";
@import "../node_modules/bulma/sass/layout/section.sass";

@font-face {
  font-family: "Font Awesome 5 Brands";
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "Font Awesome 5 Solid";
  font-style: normal;
  font-weight: normal;
}

.fab {
  font-family: "Font Awesome 5 Brands";
}

.fas {
  font-family: "Font Awesome 5 Solid";
}

body {
  --hover-color: #fea57f;
  margin: 0;
  padding: 0;
}

#modal {
  cursor: grab;
  position: absolute;
  background-color: #263a4b;
  opacity: 0.5;
  width: 100vw;
  height: 100vh;
  z-index: 5;
  transition: background-color 0.15s ease;
}

p {
  margin-bottom: 1em;
}

#map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}

img {
  margin: 0;
  max-width: 200px;
}

a {
  transition: background-color 0.2s ease;
  color: #00bca0;
}

a:hover {
  color: var(--hover-color);
  text-decoration: underline;
}

.bronnen {
  padding: 3px 5px;
  font-size: 90%;
  background-color: #e8e8e8;
  border-radius: 3px;
}

.mapboxgl-popup {
  min-width: 250px;
  max-height: 80vh;
}

.mapboxgl-popup-content strong {
  font-size: 1.5em;
}

.mapboxgl-popup-content .kleinkopje {
  text-decoration: underline;
  font-size: 1.3em;
}

.mapboxgl-popup-close-button {
  transition: background-color 0.2s ease;
  font-size: 2em;
}

.mapboxgl-popup-close-button:hover {
  background: var(--hover-color) !important;
  color: #fff;
}

.mapboxgl-popup-content {
  padding: 1.3em !important;
  overflow-y: scroll !important;
  box-shadow: 1.5px 1.5px 6px 0px rgba(0, 0, 0, 0.75) !important;
}

.popup {
  position: absolute;
  background: white !important;
  border-radius: 15px;
  padding: 20px;
  overflow-y: scroll;
  box-shadow: 3px 3px 6px 0px rgba(0, 0, 0, 0.75);
}

#welcome {
  max-height: 90%;
  max-width: 100ch;
  top: 5%;
  left: 25%;
  width: 50%;
  z-index: 6;
}

#koopDan {
  bottom: 5%;
  right: 10%;
  width: 80%;
}

.close {
  cursor: pointer;
  position: sticky !important;
  font-size: 1.1em;
  float: right;
  z-index: 10;
  top: 10px;
  right: 20px;
  transition: background-color 0.2s ease;
  padding: 12px 12px !important;
  background: #ccc;
  border-radius: 3px;
}

.close:hover {
  background: var(--hover-color);
  color: #fff;
}

#share-buttons {
  display: inline;
}

#infoIcon {
  position: absolute;
  z-index: 10000;
  left: 0.2vw;
  cursor: context-menu;
}

#infoIcon:hover {
  color: var(--hover-color);
}

#savelsbos {
  background-image: linear-gradient(to right, #ffad5b, #ff8aef 100%);
}

.route {
  display: block;
  transition: background-color 0.2s ease;
  margin: 5px 0px;
  position: relative;
  width: 50%;
  height: 40px;
  padding: 10px;
  border: none;
  border-radius: 3px;
  font-size: 12px;
  text-align: center;
  color: #fff;
  background: #00d1b2;
}

.kopen {
  background: coral;
  border: none;
  color: #fff !important;
  min-width: 166px;
  padding: 10px 20px;
  border-radius: 28px;
  cursor: pointer;
  transition: 0.05s ease;
  text-align: center;
  display: inline-block;
}

.kopen:hover {
  background: gold;
  transform: scale(1.08);
  color: #000;
  text-decoration: underline;
}

.route:hover {
  background: var(--hover-color);
}

.stukje {
  margin-top: 15px;
}

/*Mobile*/
@media only screen and (max-width: 770px) {
  .popup {
    position: absolute;
    width: 80% !important;
    left: 10% !important;
  }
  .mapboxgl-popup {
    transform: none !important;
    top: 3vh;
    width: 88vw;
  }
  .mapboxgl-popup-close-button {
    right: 0;
  }
  .mapboxgl-popup-tip {
    display: none;
  }
  #info {
    left: 5px;
    bottom: 30px;
  }
}
